<template>
  <form class="newProgram" @submit.prevent="sendForm">
    <h2 class="newProgram__titleBox">
      <svg class="newProgram__arrow" @click="goToProgramsList">
        <use xlink:href="#pagination-arrow-left-icon--sprite" />
      </svg>
      {{ programId ? "Изменение программы" : "Новая программа" }}
    </h2>

    <CommonNavigation
      class="base-navigation"
      v-if="programId"
      :nav-list="navList"
      isQuery
    />

    <div v-if="!hasEducationPlan">
      <div class="newProgram__content">
        <CommonFormItem title="О программе" hasHiding>
          <div class="newProgram__formContent">
            <CommonInput
              v-model="formModel.title"
              @input="onUpdatedValue($event, { value1: 'title' })"
            >
              Название
            </CommonInput>

            <CommonInput
              v-model="formModel.desc.header"
              @input="
                onUpdatedValue($event, { value1: 'desc', value2: 'header' })
              "
            >
              Краткое описание
            </CommonInput>

            <CommonInput
              v-model="formModel.desc.desc"
              @input="
                onUpdatedValue($event, { value1: 'desc', value2: 'desc' })
              "
            >
              Описание
            </CommonInput>

            <CommonInput
              v-model="formModel.for_whom"
              @input="onUpdatedValue($event, { value1: 'for_whom' })"
            >
              Для кого
            </CommonInput>

            <div>
              <p class="newProgram__label">Баннер для списка и описания</p>
              <input
                type="file"
                @change="onFileChange($event, 3)"
                accept="image/*"
                @input="
                  onUpdatedValue($event, { value1: 'media', value2: 'banner1' })
                "
              />
              <div v-if="images.banner1">
                <img
                  :src="images.banner1"
                  alt="Uploaded Image"
                  class="newProgram__img"
                />
              </div>
            </div>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Подробности обучения" hasHiding>
          <div class="newProgram__formContent">
            <div class="newProgram__periodBox">
              <CommonSelect
                class="newProgram__periodSelect"
                :select-options="learningPeriods"
                :hidden-text="'Выберите место'"
                :value="pickedPeriod"
                v-model="pickedPeriod"
                @input="resetTime"
              >
                Длительность обучения
              </CommonSelect>

              <CommonInput
                v-model="formModel.learning_time[pickedPeriodValue]"
                type="number"
                class="newProgram__learningTime"
                @input="
                  onUpdatedValue($event, {
                    value1: 'learning_lime',
                    value2: pickedPeriodValue,
                  })
                "
              />
            </div>

            <CommonInput
              v-model="formModel.learning_format"
              @input="onUpdatedValue($event, { value1: 'learning_format' })"
            >
              Формат обучения
            </CommonInput>

            <CommonInput
              v-model="formModel.form"
              @input="onUpdatedValue($event, { value1: 'form' })"
            >
              Форма обучения
            </CommonInput>

            <CommonInput
              v-model="formModel.cost"
              type="number"
              @input="onUpdatedValue($event, { value1: 'cost' })"
            >
              Стоимость, руб
            </CommonInput>

            <CommonInput
              v-model="formModel.number_of_places"
              type="number"
              @input="onUpdatedValue($event, { value1: 'number_of_places' })"
            >
              Количество мест
            </CommonInput>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Цели" hasHiding>
          <div class="newProgram__formContent">
            <CommonInput
              v-model="formModel.goal.goal_text"
              @input="
                onUpdatedValue($event, { value1: 'goal', value2: 'goal_text' })
              "
            >
              Описание
            </CommonInput>

            <h4 class="newProgram__title">Список целей</h4>

            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.goal.list1"
              :key="`goal-${i}`"
            >
              <CommonInput
                v-model="formModel.goal.list1[i]"
                placeholder="Цель"
                @input="
                  onUpdatedValue(
                    $event,
                    { value1: 'goal', value2: 'list1', value3: i },
                    [2]
                  )
                "
              >
                <template #suffix>
                  <svg
                    class="newProgram__close"
                    @click="removeItem(formModel.goal.list1, i)"
                  >
                    <use xlink:href="#close-icon--sprite" />
                  </svg>
                </template>
              </CommonInput>
            </div>

            <svg
              class="newProgram__plus"
              @click="pushNewItem(formModel.goal.list1, '')"
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Дополнительные цели" hasHiding>
          <div class="newProgram__formContent">
            <CommonInput
              v-model="formModel.goal.goal2.text"
              @input="
                onUpdatedValue($event, {
                  value1: 'goal',
                  value2: 'goal2',
                  value3: 'text',
                })
              "
            >
              Описание
            </CommonInput>

            <h4 class="newProgram__title">Список дополнительных целей</h4>

            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.goal.goal2.list"
              :key="`goal-${i}`"
            >
              <CommonInput
                v-model="formModel.goal.goal2.list[i]"
                placeholder="Цель"
                @input="
                  onUpdatedValue(
                    $event,
                    {
                      value1: 'goal',
                      value2: 'goal2',
                      value3: 'list',
                    },
                    [2]
                  )
                "
              >
                <template #suffix>
                  <svg
                    class="newProgram__close"
                    @click="removeItem(formModel.goal.goal2.list, i)"
                  >
                    <use xlink:href="#close-icon--sprite" />
                  </svg>
                </template>
              </CommonInput>
            </div>

            <svg
              class="newProgram__plus"
              @click="pushNewItem(formModel.goal.goal2.list, '')"
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Преимущества" hasHiding>
          <div class="newProgram__formContent">
            <h4 class="newProgram__title">Главные</h4>

            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.advantages.main"
              :key="`goal-${i}`"
            >
              <CommonInput
                v-model="formModel.advantages.main[i]"
                placeholder="Преимущество"
                @input="
                  onUpdatedValue(
                    $event,
                    { value1: 'advantages', value2: 'main', value3: i },
                    [2]
                  )
                "
              >
                <template #suffix>
                  <svg
                    class="newProgram__close"
                    @click="removeItem(formModel.advantages.main, i)"
                  >
                    <use xlink:href="#close-icon--sprite" />
                  </svg>
                </template>
              </CommonInput>
            </div>

            <svg
              class="newProgram__plus"
              @click="pushNewItem(formModel.advantages.main, '')"
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>
          </div>

          <div class="newProgram__formContent">
            <h4 class="newProgram__title">Дополнительные</h4>

            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.advantages.about"
              :key="`goal-${i}`"
            >
              <CommonInput
                v-model="formModel.advantages.about[i]"
                placeholder="Преимущество"
                @input="
                  onUpdatedValue(
                    $event,
                    { value1: 'advantages', value2: 'about', value3: i },
                    [2]
                  )
                "
              >
                <template #suffix>
                  <svg
                    class="newProgram__close"
                    @click="removeItem(formModel.advantages.about, i)"
                  >
                    <use xlink:href="#close-icon--sprite" />
                  </svg>
                </template>
              </CommonInput>
            </div>

            <svg
              class="newProgram__plus"
              @click="pushNewItem(formModel.advantages.about, '')"
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Вы научитесь" hasHiding>
          <div class="newProgram__formContent">
            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.skills"
              :key="`goal-${i}`"
            >
              <CommonInput
                v-model="formModel.skills[i]"
                placeholder="Описание"
                @input="
                  onUpdatedValue($event, { value1: 'skills', value2: i }, 1)
                "
              >
                <template #suffix>
                  <svg
                    class="newProgram__close"
                    @click="removeItem(formModel.skills, i)"
                  >
                    <use xlink:href="#close-icon--sprite" />
                  </svg>
                </template>
              </CommonInput>
            </div>

            <svg
              class="newProgram__plus"
              @click="pushNewItem(formModel.skills, '')"
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>
          </div>
        </CommonFormItem>

        <CommonFormItem
          title="Возможности карьеры после окончания программы"
          hasHiding
        >
          <div class="newProgram__formContent">
            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.career"
              :key="`goal-${i}`"
            >
              <hr class="newProgram__hr" v-if="i !== 0" />

              <div class="newProgram__formContent">
                <CommonInput
                  v-model="goal.title"
                  placeholder="Название"
                  @input="
                    onUpdatedValue(
                      $event,
                      {
                        value1: 'career',
                        value2: i,
                        value3: 'title',
                      },
                      [1]
                    )
                  "
                >
                  Название
                </CommonInput>

                <CommonInput
                  v-model="goal.desc"
                  placeholder="Описание"
                  @input="
                    onUpdatedValue(
                      $event,
                      {
                        value1: 'career',
                        value2: i,
                        value3: 'desc',
                      },
                      1
                    )
                  "
                >
                  Описание
                </CommonInput>
              </div>

              <button
                class="newProgram__delete"
                @click="removeItem(formModel.career, i)"
              >
                Удалить
              </button>
            </div>

            <svg
              class="newProgram__plus"
              @click="pushNewItem(formModel.career, { title: '', desc: '' })"
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Как проходит обучение" hasHiding>
          <div class="newProgram__formContent">
            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.how_learn_going"
              :key="`goal-${i}`"
            >
              <hr class="newProgram__hr" v-if="i !== 0" />

              <div class="newProgram__formContent">
                <CommonInput
                  v-model="goal.title"
                  placeholder="Название"
                  @input="
                    onUpdatedValue(
                      $event,
                      {
                        value1: 'how_learn_going',
                        value2: i,
                        value3: 'title',
                      },
                      [1]
                    )
                  "
                >
                  Название
                </CommonInput>

                <CommonInput v-model="goal.desc" placeholder="Описание">
                  Описание
                </CommonInput>
              </div>

              <button
                class="newProgram__delete"
                @click="removeItem(formModel.how_learn_going, i)"
              >
                Удалить
              </button>
            </div>

            <svg
              class="newProgram__plus"
              @click="
                pushNewItem(formModel.how_learn_going, { title: '', desc: '' })
              "
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Учителя" hasHiding>
          <div class="newProgram__formContent">
            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.teachers"
              :key="`goal-${i}`"
            >
              <CommonInput
                v-model="formModel.teachers[i]"
                @input="
                  onUpdatedValue(
                    $event,
                    {
                      value1: 'teachers',
                      value2: i,
                    },
                    [1]
                  )
                "
              >
                <template #suffix>
                  <svg
                    class="newProgram__close"
                    @click="removeItem(formModel.teachers, i)"
                  >
                    <use xlink:href="#close-icon--sprite" />
                  </svg>
                </template>
              </CommonInput>
            </div>

            <svg
              class="newProgram__plus"
              @click="pushNewItem(formModel.teachers, '')"
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Структура образовательной программы" hasHiding>
          <div class="newProgram__formContent">
            <CommonInput
              v-model="formModel.plan.text"
              placeholder="Описание"
              @input="
                onUpdatedValue($event, { value1: 'plan', value2: 'text' })
              "
            >
              Описание
            </CommonInput>

            <h4 class="newProgram__title">Периоды</h4>

            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.plan.list"
              :key="`goal-${i}`"
            >
              <hr class="newProgram__hr" v-if="i !== 0" />

              <div class="newProgram__formContent">
                <CommonInput
                  v-model="goal.period.name"
                  placeholder="Название периода"
                  @input="
                    onUpdatedValue($event, { value1: 'period', value2: 'name' })
                  "
                >
                  Название периода
                </CommonInput>

                <CommonInput
                  v-model="goal.period.date"
                  placeholder="Даты"
                  @input="
                    onUpdatedValue($event, { value1: 'period', value2: 'date' })
                  "
                >
                  Даты
                </CommonInput>

                <CommonSelect
                  :select-options="disciplinesListCopy[i]"
                  :hidden-text="'Выберите из списка'"
                  is-multiselect
                  @input="setPickedDisciplinesForUpdate"
                >
                  Название дисциплины
                </CommonSelect>
              </div>

              <div class="newProgram__pickedList">
                <div
                  class="newProgram__pickedItem"
                  v-for="item in pickedDisciplines(i).value"
                  :key="item.value"
                >
                  {{ item.label }}

                  <svg
                    class="newProgram__close"
                    @click="removePickedValue(i, item.value)"
                  >
                    <use xlink:href="#close-icon--sprite" />
                  </svg>
                </div>
              </div>

              <button
                class="newProgram__delete"
                @click="removeItem(formModel.plan.list, i)"
              >
                Удалить
              </button>
            </div>

            <svg
              class="newProgram__plus"
              @click="
                pushDisciplines();
                pushNewItem(formModel.plan.list, {
                  period: {
                    name: '',
                    date: '',
                  },
                  disciplines: [],
                });
              "
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Сертификаты" hasHiding>
          <div class="newProgram__formContent">
            <div>
              <p class="newProgram__label">Загрузить фото сертификата</p>

              <input
                type="file"
                @change="onFileChange($event, 1)"
                accept="image/*"
                @input="
                  onUpdatedValue($event, {
                    value1: 'media',
                    value2: 'certificate_image',
                  })
                "
              />
              <div v-if="images.certificate_image">
                <img
                  :src="images.certificate_image"
                  alt="Uploaded Image"
                  class="newProgram__img"
                />
              </div>
            </div>

            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.certificate.desc"
              :key="`goal-${i}`"
            >
              <CommonInput
                v-model="formModel.certificate.desc[i]"
                placeholder="Текст"
                @input="
                  onUpdatedValue(
                    $event,
                    {
                      value1: 'certificate',
                      value2: 'desc',
                      value3: i,
                    },
                    [2]
                  )
                "
              >
                <template #suffix>
                  <svg
                    class="newProgram__close"
                    @click="removeItem(formModel.certificate.desc, i)"
                  >
                    <use xlink:href="#close-icon--sprite" />
                  </svg>
                </template>
              </CommonInput>
            </div>

            <svg
              class="newProgram__plus"
              @click="pushNewItem(formModel.certificate.desc, '')"
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>

            <CommonInput
              v-model="formModel.certificate.type"
              @input="
                onUpdatedValue($event, {
                  value1: 'certificate',
                  value2: 'type',
                })
              "
            >
              Тип
            </CommonInput>

            <CommonInput
              v-model="formModel.certificate.title"
              @input="
                onUpdatedValue($event, {
                  value1: 'certificate',
                  value2: 'title',
                })
              "
            >
              Название
            </CommonInput>

            <h4 class="newProgram__title">Список</h4>

            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.certificate.list"
              :key="`goal-${i}`"
            >
              <CommonInput
                v-model="formModel.certificate.list[i]"
                placeholder="Текст"
                @input="
                  onUpdatedValue(
                    $event,
                    {
                      value1: 'certificate',
                      value2: 'list',
                      value3: i,
                    },
                    [2]
                  )
                "
              >
                <template #suffix>
                  <svg
                    class="newProgram__close"
                    @click="removeItem(formModel.certificate.list, i)"
                  >
                    <use xlink:href="#close-icon--sprite" />
                  </svg>
                </template>
              </CommonInput>
            </div>

            <svg
              class="newProgram__plus"
              @click="pushNewItem(formModel.certificate.list, '')"
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>

            <CommonInput
              v-model="formModel.certificate.details"
              @input="
                onUpdatedValue($event, {
                  value1: 'certificate',
                  value2: 'details',
                })
              "
            >
              Аннотация
            </CommonInput>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Отзывы" hasHiding>
          <div class="newProgram__formContent">
            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.reviews"
              :key="`goal-${i}`"
            >
              <hr class="newProgram__hr" v-if="i !== 0" />

              <div class="newProgram__formContent">
                <CommonInput
                  v-model="goal.name"
                  @input="
                    onUpdatedValue(
                      $event,
                      {
                        value1: 'reviews',
                        value2: i,
                        value3: 'name',
                      },
                      [1]
                    )
                  "
                >
                  ФИО
                </CommonInput>

                <CommonInput
                  v-model="goal.position"
                  @input="
                    onUpdatedValue(
                      $event,
                      {
                        value1: 'reviews',
                        value2: i,
                        value3: 'position',
                      },
                      [1]
                    )
                  "
                >
                  Должность
                </CommonInput>

                <CommonInput
                  v-model="goal.text"
                  @input="
                    onUpdatedValue(
                      $event,
                      {
                        value1: 'reviews',
                        value2: i,
                        value3: 'text',
                      },
                      [1]
                    )
                  "
                >
                  Текст
                </CommonInput>

                <CommonInput
                  v-model="goal.video"
                  @input="
                    onUpdatedValue(
                      $event,
                      {
                        value1: 'reviews',
                        value2: i,
                        value3: 'video',
                      },
                      [1]
                    )
                  "
                >
                  URL видео отзыва
                </CommonInput>
              </div>

              <button
                class="newProgram__delete"
                @click="removeItem(formModel.reviews, i)"
              >
                Удалить
              </button>
            </div>

            <svg
              class="newProgram__plus"
              @click="
                pushNewItem(formModel.reviews, {
                  name: '',
                  position: '',
                  text: '',
                  video: '',
                })
              "
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>
          </div>
        </CommonFormItem>

        <CommonFormItem title="FAQ" hasHiding>
          <div class="newProgram__formContent">
            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.faq"
              :key="`goal-${i}`"
            >
              <hr class="newProgram__hr" v-if="i !== 0" />

              <div class="newProgram__formContent">
                <CommonInput
                  v-model="goal.question"
                  @input="
                    onUpdatedValue(
                      $event,
                      {
                        value1: 'faq',
                        value2: i,
                        value3: 'question',
                      },
                      [1]
                    )
                  "
                >
                  Вопрос
                </CommonInput>

                <CommonInput
                  v-model="goal.answer"
                  @input="
                    onUpdatedValue(
                      $event,
                      {
                        value1: 'faq',
                        value2: i,
                        value3: 'answer',
                      },
                      [1]
                    )
                  "
                >
                  Ответ
                </CommonInput>
              </div>

              <button
                class="newProgram__delete"
                @click="removeItem(formModel.faq, i)"
              >
                Удалить
              </button>
            </div>

            <svg
              class="newProgram__plus"
              @click="
                pushNewItem(formModel.faq, {
                  question: '',
                  answer: '',
                })
              "
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Приемная кампания" hasHiding>
          <CommonInput
            type="date"
            class="newProgram__date"
            v-model="formModel.reception_company.start_reception_documents"
            @input="
              onUpdatedValue($event, {
                value1: 'reception_company',
                value2: 'start_reception_documents',
              })
            "
          >
            Начало приема документов
          </CommonInput>

          <div class="newProgram__formContent">
            <h4 class="newProgram__title">Описание</h4>

            <div class="newProgram__section">
              <div
                class="newProgram__list"
                v-for="(goal, i) in formModel.reception_company.desc"
                :key="`goal-${i}`"
              >
                <hr class="newProgram__hr" v-if="i !== 0" />

                <div class="newProgram__formContent">
                  <CommonInput
                    v-model="goal.header"
                    @input="
                      onUpdatedValue(
                        $event,
                        {
                          value1: 'reception_company',
                          value2: 'desc',
                          value3: i,
                          value4: 'header',
                        },
                        [3]
                      )
                    "
                  >
                    Заголовок
                  </CommonInput>

                  <CommonInput v-model="goal.text">Текст</CommonInput>
                </div>

                <button
                  class="newProgram__delete"
                  @click="removeItem(formModel.reception_company.desc, i)"
                >
                  Удалить
                </button>
              </div>

              <svg
                class="newProgram__plus"
                @click="
                  pushNewItem(formModel.reception_company.desc, {
                    header: '',
                    text: '',
                  })
                "
              >
                <use xlink:href="#plus-curator--sprite" />
              </svg>
            </div>

            <h4 class="newProgram__title">Панель 1</h4>

            <div class="newProgram__section">
              <div
                class="newProgram__list"
                v-for="(goal, i) in formModel.reception_company.reception_bar_1"
                :key="`goal-${i}`"
              >
                <hr class="newProgram__hr" v-if="i !== 0" />

                <CommonInput
                  v-model="goal.title"
                  @input="
                    onUpdatedValue(
                      $event,
                      {
                        value1: 'reception_company',
                        value2: 'reception_bar_1',
                        value3: i,
                        value4: 'title',
                      },
                      [2]
                    )
                  "
                >
                  Название
                </CommonInput>

                <div class="newProgram__section">
                  <div class="newProgram__formContent">
                    <h6 class="newProgram__title">Список</h6>

                    <div
                      class="newProgram__list"
                      v-for="(item, index) in goal.list"
                      :key="`goal-${index}`"
                    >
                      <CommonInput
                        v-model="
                          formModel.reception_company.reception_bar_1[i].list[
                            index
                          ]
                        "
                        @input="
                          onUpdatedValue(
                            $event,
                            {
                              value1: 'reception_company',
                              value2: 'reception_bar_1',
                              value3: i,
                              value4: 'list',
                              value5: index,
                            },
                            [2, 4]
                          )
                        "
                      >
                        <template #suffix>
                          <svg
                            class="newProgram__close"
                            @click="removeItem(goal.list, index)"
                          >
                            <use xlink:href="#close-icon--sprite" />
                          </svg>
                        </template>
                      </CommonInput>
                    </div>

                    <svg
                      class="newProgram__plus"
                      @click="
                        pushNewItem(
                          formModel.reception_company.reception_bar_1[i].list,
                          ''
                        )
                      "
                    >
                      <use xlink:href="#plus-curator--sprite" />
                    </svg>
                  </div>
                </div>

                <button
                  class="newProgram__delete"
                  @click="
                    removeItem(formModel.reception_company.reception_bar_1, i)
                  "
                >
                  Удалить
                </button>
              </div>

              <svg
                class="newProgram__plus"
                @click="
                  pushNewItem(formModel.reception_company.reception_bar_1, {
                    title: '',
                    list: [],
                  })
                "
              >
                <use xlink:href="#plus-curator--sprite" />
              </svg>
            </div>

            <h4 class="newProgram__title">Панель 2</h4>

            <div class="newProgram__section">
              <div
                class="newProgram__list"
                v-for="(goal, i) in formModel.reception_company.reception_bar_2"
                :key="`goal-${i}`"
              >
                <hr class="newProgram__hr" v-if="i !== 0" />

                <CommonInput
                  v-model="goal.title"
                  @input="
                    onUpdatedValue(
                      $event,
                      {
                        value1: 'reception_company',
                        value2: 'reception_bar_2',
                        value3: i,
                        value4: 'title',
                      },
                      [2]
                    )
                  "
                >
                  Название
                </CommonInput>

                <CommonInput
                  v-model="goal.date"
                  @input="
                    onUpdatedValue(
                      $event,
                      {
                        value1: 'reception_company',
                        value2: 'reception_bar_2',
                        value3: i,
                        value4: 'date',
                      },
                      [2]
                    )
                  "
                >
                  Даты
                </CommonInput>

                <button
                  class="newProgram__delete"
                  @click="
                    removeItem(formModel.reception_company.reception_bar_2, i)
                  "
                >
                  Удалить
                </button>
              </div>

              <svg
                class="newProgram__plus"
                @click="
                  pushNewItem(formModel.reception_company.reception_bar_2, {
                    title: '',
                    date: '',
                  })
                "
              >
                <use xlink:href="#plus-curator--sprite" />
              </svg>
            </div>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Документы" hasHiding>
          <div class="newProgram__formContent">
            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.documents"
              :key="`goal-${i}`"
            >
              <hr class="newProgram__hr" v-if="i !== 0" />

              <CommonInput
                v-model="goal.title"
                @input="
                  onUpdatedValue(
                    $event,
                    {
                      value1: 'documents',
                      value2: i,
                      value3: 'title',
                    },
                    [1]
                  )
                "
              >
                Название раздела документов
              </CommonInput>

              <div class="newProgram__section">
                <div class="newProgram__formContent">
                  <h6 class="newProgram__title">Список</h6>

                  <div
                    class="newProgram__list"
                    v-for="(item, index) in goal.list"
                    :key="`goal-${index}`"
                  >
                    <div class="newProgram__textareaWrap">
                      <textarea
                        class="newProgram__textarea"
                        placeholder="Cсылка на документ"
                        v-model="formModel.documents[i].list[index]"
                        @input="
                          onUpdatedValue(
                            $event,
                            {
                              value1: 'documents',
                              value2: i,
                              value3: 'list',
                              value4: index,
                            },
                            [1, 3]
                          )
                        "
                      />

                      <svg
                        class="newProgram__close newProgram__closeTextarea"
                        @click="removeItem(goal.list, index)"
                      >
                        <use xlink:href="#close-icon--sprite" />
                      </svg>
                    </div>
                  </div>

                  <svg
                    class="newProgram__plus"
                    @click="pushNewItem(goal.list, '')"
                  >
                    <use xlink:href="#plus-curator--sprite" />
                  </svg>
                </div>
              </div>

              <button
                class="newProgram__delete"
                @click="removeItem(formModel.documents, i)"
              >
                Удалить
              </button>
            </div>

            <svg
              class="newProgram__plus"
              @click="
                pushNewItem(formModel.documents, {
                  title: '',
                  list: [],
                })
              "
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Контакты" hasHiding>
          <div class="newProgram__formContent">
            <div>
              <p class="newProgram__label">Фото куратора</p>

              <input
                type="file"
                @change="onFileChange($event, 2)"
                accept="image/*"
                @input="
                  onUpdatedValue($event, {
                    value1: 'media',
                    value2: 'manager_photo',
                  })
                "
              />
              <div v-if="images.manager_photo">
                <img
                  :src="images.manager_photo"
                  alt="Uploaded Image"
                  class="newProgram__img"
                />
              </div>
            </div>

            <CommonInput
              v-model="formModel.contacts.manager.fullname"
              @input="
                onUpdatedValue($event, {
                  value1: 'contacts',
                  value2: 'manager',
                  value3: 'fullname',
                })
              "
            >
              ФИО
            </CommonInput>

            <CommonInput
              v-model="formModel.contacts.manager.desc"
              @input="
                onUpdatedValue($event, {
                  value1: 'contacts',
                  value2: 'manager',
                  value3: 'desc',
                })
              "
            >
              Описание
            </CommonInput>

            <CommonInput
              v-model="formModel.contacts.works_time"
              @input="
                onUpdatedValue($event, {
                  value1: 'contacts',
                  value2: 'works_time',
                })
              "
            >
              Рабочее время
            </CommonInput>

            <h6 class="newProgram__title">Почта</h6>

            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.contacts.emails"
              :key="`goal-${i}`"
            >
              <CommonInput
                v-model="formModel.contacts.emails[i]"
                placeholder="Почта"
                @input="
                  onUpdatedValue(
                    $event,
                    {
                      value1: 'contacts',
                      value2: 'emails',
                      value3: i,
                    },
                    [2]
                  )
                "
              >
                <template #suffix>
                  <svg
                    class="newProgram__close"
                    @click="removeItem(formModel.contacts.emails, i)"
                  >
                    <use xlink:href="#close-icon--sprite" />
                  </svg>
                </template>
              </CommonInput>
            </div>

            <svg
              class="newProgram__plus"
              @click="pushNewItem(formModel.contacts.emails, '')"
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>

            <h6 class="newProgram__title">Телефоны</h6>

            <div
              class="newProgram__list"
              v-for="(goal, i) in formModel.contacts.phones"
              :key="`goal-${i}`"
            >
              <CommonInput
                v-model="formModel.contacts.phones[i]"
                placeholder="Телефон"
                @input="
                  onUpdatedValue(
                    $event,
                    {
                      value1: 'contacts',
                      value2: 'phones',
                      value3: i,
                    },
                    [2]
                  )
                "
              >
                <template #suffix>
                  <svg
                    class="newProgram__close"
                    @click="removeItem(formModel.contacts.phones, i)"
                  >
                    <use xlink:href="#close-icon--sprite" />
                  </svg>
                </template>
              </CommonInput>
            </div>

            <svg
              class="newProgram__plus"
              @click="pushNewItem(formModel.contacts.phones, '')"
            >
              <use xlink:href="#plus-curator--sprite" />
            </svg>

            <CommonInput
              v-model="formModel.contacts.address"
              @input="
                onUpdatedValue($event, {
                  value1: 'contacts',
                  value2: 'address',
                })
              "
            >
              Адрес
            </CommonInput>
          </div>
        </CommonFormItem>

        <CommonFormItem title="Дата и статус" hasHiding>
          <div class="newProgram__formContent">
            <div class="newProgram__datesBox">
              <CommonInput
                type="date"
                class="newProgram__date"
                v-model="formModel.start_at"
                @input="
                  onUpdatedValue($event, {
                    value1: 'start_at',
                  })
                "
              >
                Дата старта
              </CommonInput>

              <CommonInput
                type="date"
                class="newProgram__date"
                v-model="formModel.end_at"
                @input="
                  onUpdatedValue($event, {
                    value1: 'end_at',
                  })
                "
              >
                Дата окончания
              </CommonInput>
            </div>

            <label for="newProgramActive">
              <input
                type="checkbox"
                id="newProgramActive"
                v-model="formModel.is_active"
                @input="
                  onUpdatedValue($event, {
                    value1: 'is_active',
                  })
                "
              />

              Активный
            </label>
          </div>
        </CommonFormItem>
      </div>

      <CommonButtonPrimary class="newProgram__button">
        Сохранить
      </CommonButtonPrimary>
    </div>

    <CuratorEducationPlan v-else />
  </form>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  reactive,
  ref,
  onMounted,
  watchEffect,
} from "vue";
import { RouteName } from "@/constants";
import { useRoute, useRouter } from "vue-router";
import { CommonNavigation } from "@/components/CommonNavigation";
import { navList, learningPeriods } from "./constants";
import { CommonFormItem } from "@/components/CommonFormItem";
import { CommonInput } from "@/components/CommonInput";
import { CommonSelect } from "@/components/CommonSelect";
import { CommonButtonPrimary } from "@/components/CommonButtonPrimary";
import { CuratorEducationPlan } from "../CuratorEducationPlan";
import store from "@/store";

export default defineComponent({
  name: "CuratorNewProgram",

  components: {
    CommonNavigation,
    CommonFormItem,
    CommonInput,
    CommonSelect,
    CommonButtonPrimary,
    CuratorEducationPlan,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const imageUrl = ref<any>("");

    const pickedPeriod = ref("Годы");
    const disciplinesListCopy = ref<any>([]);

    const formModel = reactive<any>({
      title: "",
      desc: {
        header: "",
        desc: "",
      },
      for_whom: "",
      learning_format: "",
      form: "",
      cost: undefined,
      number_of_places: undefined,
      learning_time: {
        hours: 0,
        weeks: 0,
        years: 0,
      },
      goal: {
        goal_text: "",
        list1: [],
        goal2: {
          text: "",
          list: [],
        },
      },
      advantages: {
        main: [],
        about: [],
      },
      skills: [],
      career: [
        {
          title: "",
          desc: "",
        },
      ],
      how_learn_going: [
        {
          title: "",
          desc: "",
        },
      ],
      teachers: [],
      plan: {
        text: "",
        list: [
          {
            period: {
              name: "",
              date: "",
            },
            disciplines: [],
          },
        ],
      },
      certificate: {
        desc: [],
        type: "",
        title: "",
        list: [],
        details: "",
      },
      media: {
        certificate_image: "" as any,
        manager_photo: "" as any,
        banner1: "" as any,
      },
      reviews: [
        {
          name: "",
          position: "",
          text: "",
          video: "",
        },
      ],
      faq: [
        {
          question: "",
          answer: "",
        },
      ],
      reception_company: {
        start_reception_documents: "",
        desc: [
          {
            header: "",
            text: "",
          },
        ],
        reception_bar_1: [
          {
            title: "",
            list: [],
          },
        ],
        reception_bar_2: [
          {
            title: "",
            date: "",
          },
        ],
      },
      documents: [
        {
          title: "",
          list: [],
        },
      ],
      contacts: {
        manager: {
          fullname: "",
          desc: "",
        },
        works_time: "",
        emails: [],
        phones: [],
        address: "",
      },
      start_at: "",
      end_at: "",
      is_active: true as boolean | number,
    });

    const updatedValues: any = {};

    const images = reactive({
      certificate_image: "" as any,
      manager_photo: "" as any,
      banner1: "" as any,
    });

    const degreeId = computed(() => route?.query?.degree_id);

    const createdStatus = computed(
      () => store.state.programsStore?.createdStatus
    );

    const degreeList = computed(() => store.getters.programsStore?.listData);

    const programId = computed(() => route.query?.program_id);

    const program = computed(() =>
      store.getters.programsStore.listData?.find(
        (item: any) => Number(item.id) === Number(programId.value)
      )
    );

    const pickedPeriodValue = computed(() => {
      if (pickedPeriod.value === "Часы") {
        return "hours";
      } else if (pickedPeriod.value === "Недели") {
        return "weeks";
      }
      return "years";
    });

    const hasRouterCurator = computed(() => route.name === RouteName.CURATOR);

    const hasEducationPlan = computed(() => Number(route.query?.id) === 2);

    const disciplinesList = computed(
      () => store.getters.programsStore?.disciplinesList
    );

    const pickedDisciplines = (index: number) =>
      computed(() =>
        disciplinesListCopy.value[index]?.filter((item: any) => item.isChecked)
      );

    const resetTime = () => {
      formModel.learning_time.hours = 0;
      formModel.learning_time.weeks = 0;
      formModel.learning_time.years = 0;
    };

    const goToProgramsList = () =>
      router.push({ query: { program_constructor: 0 } });

    const pushNewItem = (list: any, pushedElem: any) => list.push(pushedElem);

    const pushDisciplines = () => {
      const list = disciplinesList.value?.map((item: any, i: number) => {
        return {
          value: String(item.id),
          label: String(item.title),
          isChecked: false,
        };
      });

      disciplinesListCopy.value.push(list);
    };

    const removeItem = (list: any, index: number) => {
      if (index === 0) {
        list.shift();
      } else {
        list.splice(index, index);
      }
    };

    const removePickedValue = (index: number, itemId: number) => {
      disciplinesListCopy.value[index].find(
        (item: any) => item.value === itemId
      ).isChecked = false;
    };

    const setPickedDisciplinesForUpdate = () => {
      if (!updatedValues?.plan) {
        updatedValues.plan = {};
      }
      updatedValues.plan.list = formModel.plan.list;
    };

    const onUpdatedValue = (
      e: any,
      values: {
        value1: any;
        value2?: any;
        value3?: any;
        value4?: any;
        value5?: any;
      },
      arrayItem?: any
    ) => {
      if (values?.value5) {
        if (arrayItem?.length && arrayItem?.includes(1)) {
          updatedValues[values?.value1] = [];
        } else {
          updatedValues[values?.value1] = {};
        }

        if (arrayItem?.length && arrayItem?.includes(2)) {
          updatedValues[values?.value1][values?.value2] = [];
        } else {
          updatedValues[values?.value1][values?.value2] = {};
        }

        if (arrayItem?.length && arrayItem?.includes(3)) {
          updatedValues[values?.value1][values?.value2][values?.value3] = [];
        } else {
          updatedValues[values?.value1][values?.value2][values?.value3] = {};
        }

        if (arrayItem?.length && arrayItem?.includes(4)) {
          updatedValues[values?.value1][values?.value2][values?.value3][
            values?.value4
          ] = [];
        } else {
          updatedValues[values?.value1][values?.value2][values?.value3][
            values?.value4
          ] = {};
        }

        updatedValues[values?.value1][values?.value2][values?.value3][
          values?.value4
        ][values?.value5] = e?.target?.value;
      } else if (values?.value4) {
        if (arrayItem?.length && arrayItem?.includes(1)) {
          updatedValues[values?.value1] = [];
        } else {
          updatedValues[values?.value1] = {};
        }

        if (arrayItem?.length && arrayItem?.includes(2)) {
          updatedValues[values?.value1][values?.value2] = [];
        } else {
          updatedValues[values?.value1][values?.value2] = {};
        }

        if (arrayItem?.length && arrayItem?.includes(3)) {
          updatedValues[values?.value1][values?.value2][values?.value3] = [];
        } else {
          updatedValues[values?.value1][values?.value2][values?.value3] = {};
        }

        updatedValues[values?.value1][values?.value2][values?.value3][
          values?.value4
        ] = e?.target?.value;
      } else if (values?.value3) {
        if (arrayItem?.length && arrayItem?.includes(1)) {
          updatedValues[values?.value1] = [];
        } else {
          updatedValues[values?.value1] = {};
        }

        if (arrayItem?.length && arrayItem?.includes(2)) {
          updatedValues[values?.value1][values?.value2] = [];
        } else {
          updatedValues[values?.value1][values?.value2] = {};
        }

        updatedValues[values?.value1][values?.value2][values?.value3] =
          e?.target?.value;
      } else if (values?.value2) {
        if (arrayItem?.length && arrayItem?.includes(2)) {
          updatedValues[values?.value1] = [];
        } else {
          updatedValues[values?.value1] = {};
        }
        updatedValues[values?.value1][values?.value2] = e?.target?.value;
      } else {
        updatedValues[values?.value1] = e?.target?.value;
      }
    };

    const sendForm = async () => {
      disciplinesListCopy.value?.forEach((item: any, i: number) => {
        const checkedDisciplines = item?.filter(
          (discipline: any) => discipline.isChecked
        );

        const mappedDisciplines = checkedDisciplines.map((discipline: any) => {
          return discipline.label;
        });

        formModel.plan.list[i].disciplines = mappedDisciplines;
      });

      const addedBodyObj = {
        degree_id: Number(degreeId.value) || 3,
      };

      formModel.is_active = Number(formModel.is_active);
      if (updatedValues?.is_active) {
        updatedValues.is_active = Number(updatedValues.is_active);
      }

      if (programId.value) {
        await store.dispatch.programsStore.createProgram({
          data: Object.assign(updatedValues, addedBodyObj),
          isUpdate: programId.value,
          program_id: +programId.value,
        });
      } else {
        await store.dispatch.programsStore.createProgram({
          data: Object.assign(formModel, addedBodyObj),
          isUpdate: programId.value,
        });
      }

      setTimeout(async () => {
        if (createdStatus.value === "FULFILLED") {
          await store.dispatch.programsStore.getDegreeList({
            degree_id: Number(degreeId.value) || 3,
          });

          router.push({
            query: {
              ...route.query,
              program_id: degreeList.value[degreeList.value?.length - 1]?.id,
            },
          });
        }
      }, 1500);
    };

    const onFileChange = (event: any, imageId: number) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (imageId === 1) {
            images.certificate_image = e?.target?.result;
            formModel.media.certificate_image = file;
          } else if (imageId === 2) {
            images.manager_photo = e?.target?.result;
            formModel.media.manager_photo = file;
          } else if (imageId === 3) {
            images.banner1 = e?.target?.result;
            formModel.media.banner1 = file;
          }
        };
        reader.readAsDataURL(file);
      }
    };

    watchEffect(() => {
      if (programId.value) {
        if (!images.certificate_image) {
          images.certificate_image =
            program.value?.media.certificate_image?.url;
        }
        if (!images.manager_photo) {
          images.manager_photo = program.value?.media.manager_photo?.url;
        }
        if (!images.banner1) {
          images.banner1 = program.value?.media.banner1?.url;
        }

        Object.keys(formModel).forEach((key: any) => {
          console.log(formModel[key], key);
          if (program.value && key in program.value && program.value[key]) {
            if (typeof formModel[key] === "string") {
              formModel[key] = program.value[key];
            } else if (Array.isArray(formModel[key])) {
              formModel[key].concat(program.value[key]);
            } else if (typeof formModel[key] === "object") {
              Object.assign(formModel[key], program.value[key]);
            }
          }
        });

        formModel.start_at = formModel.start_at.slice(0, 10);
        formModel.end_at = formModel.end_at.slice(0, 10);
      }
    });

    onMounted(async () => {
      await store.dispatch.programsStore.getDisciplines({
        degree_id: Number(degreeId.value) || 3,
      });
      pushDisciplines();
    });

    return {
      imageUrl,
      disciplinesList,
      disciplinesListCopy,
      hasRouterCurator,
      navList,
      formModel,
      images,
      learningPeriods,
      pickedPeriod,
      pickedPeriodValue,
      hasEducationPlan,
      programId,
      updatedValues,
      setPickedDisciplinesForUpdate,
      onUpdatedValue,
      onFileChange,
      pickedDisciplines,
      removeItem,
      removePickedValue,
      resetTime,
      goToProgramsList,
      pushNewItem,
      pushDisciplines,
      sendForm,
    };
  },
});
</script>

<style lang="stylus" scoped>
.newProgram {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__titleBox {
    padding: 15px;
    border-radius: 10px;
    background-color: $colorLightGrey;
  }

  &__title {
    margin-top: 10px;
    font-weight: normal;
  }

  &__arrow {
    width: 32px;
    height: 32px;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__formContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__periodBox {
    display: flex;
    align-items: flex-end;
    gap: 10px;
  }

  &__periodSelect {
    max-width: 220px;
  }

  &__learningTime {
    width: fit-content;
  }

  &__close {
    width: 12px;
    height: 12px;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  &__plus {
    margin-top: 10px;
    width: 28px;
    height: 28px;
    &:hover {
      cursor: pointer;
      opacity: .6;
    }
  }

  &__delete {
    margin-top: 10px;
    border: none;
    border-bottom: 1px dotted $colorGreenMedium;
    color: $colorGreenMedium;
    background-color: transparent;
  }

  &__pickedList {
    margin-top: 15px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }

  &__pickedItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: $colorFiller;
    border-radius: 10px;
  }

  &__section {
    margin-top: 10px;
    padding: 24px;
    border: 1px solid $colorMediumGrey;
    border-radius: 10px;
  }

  &__date {
    width: fit-content;
  }

  &__textareaWrap {
    position: relative;
  }

  &__textarea {
    padding: 30px;
    width: 100%;
    height: 10vw;
    resize: none;
    border-radius: 10px;

    &:focus {
      outline: none;
      border: 1px solid $colorPrimary;
    }
  }

  &__closeTextarea {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__datesBox {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__button {
    width: fit-content;
  }

  &__img {
    margin-top: 10px;
    width: 100%;

    +mediaTablet() {
      width: 40%;
    }
  }

  &__label {
    margin: 0;
    margin-bottom: 10px;
    font-size: 12px;

    +mediaTablet() {
      font-size: .7vw;
    }
  }

  &__button {
    margin-top: 15px;
  }

  +mediaTablet() {
    &__pickedList {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
input[type='file'] {
  color: transparent;
}
</style>
